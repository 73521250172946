/*
	
	Video

*/

/*---- Video header ----*/
.c-page-video__poster {
	position: absolute;
	z-index: 10;
	height: 100%;
	background: $black;

	> * {
		opacity: 0;
	}

	&.is-video-loaded {
		background: transparent;
	}

	&.is-video-stopped {
		/* Not using placeholder here as its being overidden by loaded class for some reason */
		background: url(../img/content/poster.jpg) 0 0 no-repeat;
		background-size: contain;

		> * {
			opacity: 1;
		}
	}

	/* Show background for certain locales */
	[data-locale="ar"] & {
		background: url(../img/content/poster.jpg) 0 0 no-repeat;
		background-size: contain;

		> * {
			opacity: 1;
		}
	}

	/* Show background image for small devices */
	@include islay_breakpoint('l', 'max') {
		background: url(../img/content/poster.jpg) 0 0 no-repeat;
		background-size: contain;

		> * {
			opacity: 1;
		}
	}
}

.c-page-video__logo {
	position: absolute;
	top: 25.1%;
	left: 21.4%;
	width: 57.2%;
	padding-bottom: 28%;
	background: url(../img/meta/break-the-sieges-logo-en.svg) center center no-repeat;
	background-size: contain;

	/* Update logo for different locales */
	[data-locale="ar"] & {
		background-image: url(../img/meta/break-the-sieges-logo-ar.png);
	}

	img {
		width: 100%;
	}
}
