.multistep {

}

.multistep__stage {
	display: none;

	&:first-child {
		display: block;
	}
}