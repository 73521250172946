/*
	
	Progress

*/


/*---- Support counter ----*/
.c-support-status {
	@extend %cf;
	color: $primary_color;
	text-transform: uppercase;
	font-family: $title_font;
	font-size: em(20);
}

.c-support-status__total {
	float: left;
	margin: 0 0 rem(5);
}

.c-support-status__target {
	float: right;
	margin: 0 0 rem(5);
}

.c-support-status__bar {
	clear: both;
}

/*---- Progress bar ----*/
.c-progress-bar {
	position: relative;
	height: rem(20);
	background: $white;
	overflow: hidden;
}

.c-progress-bar__fill {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 0;
	background: $primary_color;
	transition: width 3s ease;

	[dir="ltr"] & {
		left: 0;
	}
	[dir="rtl"] & {
		right: 0;
	}
}

/*---- Progress bar types ----*/
.c-action-progress {
	@extend .cf;
	color: $white;
	font-family: $title_font;
	text-transform: uppercase;

	p {
		margin-bottom: rem(10);
		white-space: nowrap;

		&:first-of-type {
			color: $primary_color;

			[dir="ltr"] & {
				float: left;
			}
			[dir="rtl"] & {
				float: right;
			}
		}

		&:last-of-type {
			[dir="ltr"] & {
				float: right;
			}
			[dir="rtl"] & {
				float: left;
			}
			+ * {
				clear: both;
			}
		}
	}
}