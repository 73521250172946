/*
	
	Social

*/

/*---- Share list ----*/

/* List layout */
.c-share-list {}

.c-share-list--l--align-3 {
	@include islay_breakpoint('l') {
		@include islay_flexgrid_row();
	}

	.c-share-list__item {
		@include islay_breakpoint('l') {
			@include islay_flexgrid_col_setup();
			@include islay_flexgrid_col_span(4, 10);

			+ * {
				margin-top: 0;
			}
		}
	}
}

.c-share-list__item {
	list-style: none;

	+ * {
		margin-top: rem(10);
	}
}

/* Table layout */
.c-share-links {
	display: table;
	table-layout: fixed;
	> * {
		display: table-cell;
		line-height: inherit;
		padding-top: 0;
		padding-bottom: 0;
		vertical-align: middle;

		+ * {
			margin-top: 0;
		}
	}
}

/*---- Iframes ----*/
.c-share-iframes {
	> * + * {
		margin-top: rem(10);
	}
}
