/*
	
	Callouts

*/

.c-callout-story {
	background: $grey;
}

.c-callout-story__wrap {
	padding: rem(20);

	> * {
		margin: 0;
		+ * {
			margin-top: rem(10);
		}
	}

	p {
		@include islay_breakpoint('l') {
			font-size: em(14);
		}
	}
}

.c-callout-story__title {
	@extend .t-scale-4;
}