@mixin islay_breakpoint($breakpoint_name, $threshold: min, $dimension: width, $unit: px) {

	$breakpoint_value: islay_get_breakpoint($breakpoint_name);

	@if (unitless($breakpoint_value)) {
		@media (#{$threshold}-#{$dimension}: em($breakpoint_value)) {
			@content;
		}
	}

	@else {
		@media (#{$threshold}-#{$dimension}: $breakpoint_value) {
			@content;
		}
	}
}