@mixin islay_generate_font_face($font_name, $class_name, $file, $fallback: sans-serif, $weight: normal, $style: normal, $namespace: $islay_namespace) {
	@font-face {
		font-family: 	#{$font_name};
		src:  			url(#{$file}.woff2) format('woff2'),
        				url(#{$file}.woff) format('woff');
		font-weight: 	#{$weight};
		font-style: 	#{$style};
	}
	
	@include islay_generate_font_face_class_name($font_name, $class_name, $fallback, $namespace);

}

@mixin islay_generate_font_face_class_name($font_name, $class_name, $fallback: sans-serif, $weight: normal, $style: normal, $namespace: $islay_namespace) {
	.#{$namespace}font-face--#{$class_name} {
		font-family: 	$font_name, $fallback;
		font-weight: 	#{$weight};
		font-style: 	#{$style};
	}
}