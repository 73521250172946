@import "settings";
@import "functions";
@import "mixins";

/* Base settings */
img {
	max-width: 100%;
}

img, video {
    vertical-align: middle;
}

figure {
	margin: 0; 
}

figcaption {
	font-size: $islay_caption_size;
}

/*
	Image placeholders (padding-bottom hack)
	http://www.smashingmagazine.com/2013/09/16/responsive-images-performance-problem-case-study
*/
.media-wrap, %media-wrap {
	position: relative;
	overflow: hidden;
	background: $islay_media_placeholder_bg;

	> * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
}

/*---- Generate aspect ratio classes ----*/
@each $class_name, $list in $islay_ratios {
	.media-wrap--#{$class_name} {
		@include islay_intrinsic_ratio(nth($list, 1), nth($list, 2));
	}
}