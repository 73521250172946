@import "mixins";

/*---- Basic visibility classes ----*/
.hidden { display: none !important; }
.visible { display: block; }
.visible--flex { display: flex; }
.visible--table { display: table; }
.visible--table-row { display: table-row; }
.visible--table-cell { display: table-cell; }
.visible--inline { display: inline; }
.visible--inline-block { display: inline-block; }

/*---- Accesibility visibility classes ----*/
.visually-hidden {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

/*---- Generate default breakpoint visibility classes ----*/
@include generate_breakpoint_visibility_classes();