/*
 *
 * Mixins for flexbox based grid
 * Requires Modernizr for no-flexbox fallback
 * TO DO: Create position mixin for moving columns horizontally across the grid
 *
**/

/* 
 * Fallback for older browsers 
 * NOTE: uses Modernizr class names
 *
 */
@mixin islay_no_flexbox() {
	.no-flexbox & {
		@content;
	}
}

/* Create gutters */
@mixin islay_flexgrid_gutter() {
	padding-left: rem($islay_flexgrid_gutter);
	padding-right: rem($islay_flexgrid_gutter);
}

/* Create gutters */
@mixin islay_flexgrid_alt_gutter() {
	margin-left: rem($islay_flexgrid_gutter);
	margin-right: rem($islay_flexgrid_gutter);
}

/* Setup a flexbox row */
@mixin islay_flexgrid_row() {
	display: flex;
	flex-direction: row;
  	flex-wrap: wrap;

  	@include islay_no_flexbox() {
  		@include clearfix();
  	}
}

/* Generate rows for specific breakpoints in sass map */
@mixin islay_flexgrid_breakpoint_rows($breakpoints: $islay_breakpoints, $namespace: $islay_namespace) {
	@each $breakpoint_name, $breakpoint_value in $breakpoints {
		@media (min-width: em($breakpoint_value)) {
			.#{$namespace}gutter--#{$breakpoint_name} {
				@include islay_flexgrid_gutter();
			}

			.#{$namespace}row--#{$breakpoint_name} {
				@include islay_flexgrid_row();
			}

			.#{$namespace}row--#{$breakpoint_name}--collapse {
				@include islay_flexgrid_collapse_row_gutters();
			}

			.#{$namespace}col--#{$breakpoint_name}--collapse {
				padding-left: 0;
				padding-right: 0;
			}

			.#{$namespace}alt-col--#{$breakpoint_name}--collapse {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}

/* Setup a flexbox column */
@mixin islay_flexgrid_col_setup() {
	position: relative;
	width: 100%;
	flex-basis: 100%;
	/* Fixes images breaking container in FF Developer Edition */
	min-width: 0;

	@include islay_no_flexbox() {
  		float: left;
  	}
}

/* Remove external gutters from row */
@mixin islay_flexgrid_collapse_row_gutters($context_gutter: $islay_flexgrid_gutter) {
	margin-left: rem(-$context_gutter);
	margin-right: rem(-$context_gutter);
}

/* Padding gutters */
@mixin islay_flexgrid_col_span($span, $context_gutter: $islay_flexgrid_gutter, $context_column_count: $islay_flexgrid_column_count) {

	/* Calculate col width from span */
	$colWidth: (100% / $context_column_count * $span);

	padding-left: rem($context_gutter);
	padding-right: rem($context_gutter);
	flex-basis: $colWidth;
	
	/* 
	* Fixes IE width issues
	* http://stackoverflow.com/questions/21942183/multiline-flexbox-in-ie11-calculating-widths-incorrectly
	*/
	max-width: $colWidth;

	/* No flexbox fallback */
	@include islay_no_flexbox() {
  		width: $colWidth;
  	}
}

/* Margin gutters */
@mixin islay_flexgrid_alt_col_span($span, $context_gutter: $islay_flexgrid_gutter, $context_column_count: $islay_flexgrid_column_count) {

	/* Calculate col width from span */
	$colWidth: (100% / $context_column_count * $span);

	/* Rem value */
	$remValue: rem($context_gutter * 2);

	margin-left: rem($context_gutter);
	margin-right: rem($context_gutter);
	flex-basis: calc(#{$colWidth} - #{$remValue});

	/* 
	* Fixes IE width issues
	* http://stackoverflow.com/questions/21942183/multiline-flexbox-in-ie11-calculating-widths-incorrectly
	*/
	max-width: calc(#{$colWidth} - #{$remValue});

	/* No flexbox fallback */
	@include islay_no_flexbox() {
  		width: $colWidth;
  	}
}

/* Off grid widths */
@mixin islay_flexgrid_non_col_span($span: 20%, $context_gutter: $islay_flexgrid_gutter) {

	$colWidth: $span;

	padding-left: rem($context_gutter);
	padding-right: rem($context_gutter);
	flex-basis: $colWidth;
	
	/* Fixes IE width issues */
	max-width: $colWidth;

	/* No flexbox fallback */
	@include islay_no_flexbox() {
  		width: $colWidth;
  	}
}

/* Shidt elements along the grid */
@mixin islay_flexgrid_shift($count, $shift: 'push', $context_gutter: $islay_flexgrid_gutter, $context_column_count: $islay_flexgrid_column_count) {
	
	/* Calculate col width from span */
	$colWidth: (100% / $context_column_count);

	@if $shift == 'pull' {
		right: ($colWidth * $count);
	}
	@else {
		right: -($colWidth * $count);
	}
}

/* Generate classes for flexgrid layouts */
@mixin islay_flexgrid_generate_col_classes($breakpoints: $islay_breakpoints, $namespace: $islay_namespace) {

	@each $breakpoint_name, $breakpoint_value in $breakpoints {
		@media (min-width: rem($breakpoint_value)) {

			@for $i from 1 through $islay_flexgrid_column_count {

				.#{$namespace}col--#{$breakpoint_name}--span-#{$i}, %#{$namespace}col--#{$breakpoint_name}--span-#{$i} {
					@include islay_flexgrid_col_setup();
					@include islay_flexgrid_col_span($i);
				}

				.#{$namespace}alt-col--#{$breakpoint_name}--span-#{$i}, %#{$namespace}alt-col--#{$breakpoint_name}--span-#{$i} {
					@include islay_flexgrid_col_setup();
					@include islay_flexgrid_alt_col_span($i);
				}
			}		
		}
	}
}