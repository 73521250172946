@import "settings";
@import "functions";

.form-group {
	margin: map-get($islay_form_group_settings, margin);

	input {
		/* Vertical alignment with labels */
		vertical-align: middle;
	}

	input[type=text], input[type=email], input[type=number], input[type=password], select, textarea {
		width: 100%;
	}

	label {
		display: inline-block;
		margin: map-get($islay_form_group_settings, label-margin);
		/* Vertical alignment with inputs */
		vertical-align: middle;
	}

	input[type=checkbox], input[type=radio] {

		+ label {
			display: inline;
		}
	}
}

.form-checkbox {

}