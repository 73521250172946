/*
	
	Page blocks

*/

/*---- Page sections ----*/
.b-page-section {
	background: $white;
}

.b-page-section__heading {
	position: relative;
	overflow: hidden;
	@include islay_intrinsic_ratio(3,2);
	background: $black;

	picture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	img {
		width: 100%;
		
		// Hack for picturefill lazyload
		// Lazyload not loading correct source for small devices
		@include islay_breakpoint('m', 'max') {
			transform: scale(1.4) translateY(14%);
		}
	}

	/* Using px to fit with html picture media query */
	@media (min-width: 768px) {
		@include islay_intrinsic_ratio(6,3);
	}
}

.b-page-section__heading-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;

	[dir="rtl"] & {
		bottom: rem(20);
	}
}

.b-page-section__quote {
	padding: rem(30) 0 rem(70);
	background: $red url(../img/backgrounds/partition-01-small.png) center bottom no-repeat;
	background-size: contain;

	@include islay_breakpoint('m') {
		padding: rem(60) 0 rem(120);
		background-image: url(../img/backgrounds/partition-01.png);
	}
}

/* Content */
.b-page-section__content {
	/* Split rules: don't collapse small section padding */
	padding-top: rem(40);
	padding-bottom: rem(80);
}