/* Font loading classes */
.wf-loading {
	h1, h2, h3, h4, h5, h6, p, ul, ol, i, input, a {
		visibility: hidden;
	}

	&.no-js {
		h1, h2, h3, h4, h5, h6, p, ul, ol, i, input, a {
			visibility: visible;
		}
	}
}

.wf-active {
    h1, h2, h3, h4, h5, h6, p, ul, ol, i, input {
		visibility: visible;
	}
}