@mixin generate_breakpoint_visibility_classes() {

  @each $breakpoint, $value in $islay_breakpoints {
    .visible--#{$breakpoint},
    .visible--#{$breakpoint}--inline,
    .visible--#{$breakpoint}--inline-block,
    .visible--#{$breakpoint}--table,
    .visible--#{$breakpoint}--table-row,
    .visible--#{$breakpoint}--table-cell,
    .visible--#{$breakpoint}--flex { display: none; }

    @include islay_breakpoint($breakpoint) {
      .visible--#{$breakpoint} { display: block; }
      .visible--#{$breakpoint}--inline { display: inline; }
      .visible--#{$breakpoint}--inline-block { display: inline-block; }
      .visible--#{$breakpoint}--table { display: table; }
      .visible--#{$breakpoint}--table-row { display: table-row; }
      .visible--#{$breakpoint}--table-cell { display: table-cell; }
      .visible--#{$breakpoint}--flex { display: flex; }
    }

    table.visible--#{$breakpoint} {
      @include islay_breakpoint($breakpoint) {
        display: table;
      }
    }

    tr.visible--#{$breakpoint} {
      @include islay_breakpoint($breakpoint) {
        display: table-row;
      }
    }

    td.visible--#{$breakpoint} {
      @include islay_breakpoint($breakpoint) {
        display: table-cell;
      }
    }

    .hidden--#{$breakpoint} {
      @include islay_breakpoint($breakpoint) {
        display: none !important;
      }
    }
  }
}