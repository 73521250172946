@mixin islay_generate_container_breakpoint_styles($container_settings: $islay_container_settings, $center: true) {
	@each $breakpoint, $map in $container_settings {
		
		$width: map-get($map, width);
		$padding: map-get($map, padding);
		$max_width: map-get($map, max-width);

		@if $center {
			margin: 0 auto;
		}

		@include islay_breakpoint($breakpoint) {
			width: $width;
			padding-left: $padding;
			padding-right: $padding;
			max-width: $max_width;
		}
	}
}