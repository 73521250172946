/*
	
	Footer

*/

.b-footer-home {
	padding: rem(20) 0 rem(60);
	background: $red;

	@include islay_breakpoint('m') {
		padding: rem(40) 0 rem(140);
	}
}

.b-footer-home__credits {
	a {
		color: $black;

		&:hover {
			color: $white;
		}
	}
}