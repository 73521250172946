/*
	
	Forms

*/


/*---- Default form styles ----*/
.c-form-default {
	/* Full width inputs */
	input, textarea, select {
		width: 100%;

		&:focus {
			outline: 3px solid $black;
		}
	}
}

/* Form rows */
.c-form-row {
	margin-bottom: rem(10);
}

/* Custom select */
.c-custom-select {
	position: relative;

	select {
		appearance: none;
		padding: map-get($islay_input_settings, padding);
		border: map-get($islay_input_settings, border);
		border-radius: map-get($islay_input_settings, border-radius);
		background: map-get($islay_input_settings, background);
		cursor: pointer;
		transition: background .1s ease;

		&:hover {
			background: darken(map-get($islay_input_settings, background), 5);
		}
	}

	/* Hide arrow for IE */
	select::-ms-expand {
		display: none;
	}

	&:before {
		@include islay_pseudo_block();
		@include islay_arrow(8px, $black, down);
		top: 50%;
		margin-top: -2px;

		[dir="ltr"] & {
			right: 10px;
		}
		[dir="rtl"] & {
			left: 10px;
		}

		.lt-ie10 & {
			display: none;
		}

		/* Hide for IE9 as can't easily hide standard select arrow */
		.no-csstransforms3d & {
			display: none;
		}
	}
}

/*---- Expanding form ----*/
.c-expanding-form {
	
	/* Hide form row content */
	&.is-contracted {
		.c-form-row--hidden {
			max-height: 0;
			margin: 0;

			* {
				opacity: 0;
			}

			/* For no JS */
			.no-js & {
				max-height: 9999px !important;
				margin-bottom: rem(10) !important;

				* {
					opacity: 1 !important;
				}
			}
		}
	}
}

.c-form-row--hidden {
	max-height: 9999px;
	transition: all .3s ease;
	
	* {
		opacity: 1;
		transition: opacity .3s ease;
	}	
}

/*---- Form errors ----*/
.c-form-error {
	display: none;
	margin: rem(6) 0 0;
	font-size: rem(13);
	color: $white;
}

/*---- Form loader ----*/
.c-form-loader {
	display: none;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	/* Don't cover submit button */
	bottom: 80px;
	width: 100%;
	background: rgba($blue,0.4);

	/* Position loader */
	> div {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

	@include islay_breakpoint('l') {
		/* Don't cover submit button */
		bottom: 30px;
	}

	&.is-loading {
		display: block;
	}
}