/*
	
	Helpers

*/

/*---- Grid overlay ----*/
.grid {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;

	.container {
		> div {
			height: 100vh;
			background: rgba(red, 0.2);
		}
	}
}

/*---- Grid helpers ----*/
.l-shift--m--1 {
	@include islay_breakpoint('m') {
		[dir="ltr"] & {
			@include islay_flexgrid_shift(1);
		}
		[dir="rtl"] & {
			@include islay_flexgrid_shift(1, 'pull');
		}
	}
}

.l-shift--m--2 {
	@include islay_breakpoint('m') {
		[dir="ltr"] & {	
			@include islay_flexgrid_shift(2);
		}
		[dir="rtl"] & {
			@include islay_flexgrid_shift(2, 'pull');
		}
	}
}

.l-shift--l--1 {
	@include islay_breakpoint('l') {
		[dir="ltr"] & {
			@include islay_flexgrid_shift(1);
		}
		[dir="rtl"] & {
			@include islay_flexgrid_shift(1, 'pull');
		}
	}
}

.l-shift--l--2 {
	@include islay_breakpoint('l') {
		[dir="ltr"] & {
			@include islay_flexgrid_shift(2);
		}
		[dir="rtl"] & {
			@include islay_flexgrid_shift(2, 'pull');
		}
	}
}

.l-shift--xl--1 {
	@include islay_breakpoint('xl') {
		[dir="ltr"] & {
			@include islay_flexgrid_shift(1);
		}
		[dir="rtl"] & {
			@include islay_flexgrid_shift(1, 'pull');
		}
	}
}

/*---- Callouts ----*/
@include islay_breakpoint('xl') {	
	[class*="l-callout-"] {
		max-width: rem(300);
		margin-top: rem(map-get($islay_flexgrid_settings, gutter));
		margin-bottom: rem(map-get($islay_flexgrid_settings, gutter));
	}

	.l-callout-left {
		float: left;
		/* Pull left 2 cols */
		margin-left: -16.666666667%;
		/* Add column gutter */
		margin-right: rem(map-get($islay_flexgrid_settings, gutter) * 2);
	}

	.l-callout-right {
		float: right;
		/* Pull right 2 cols */
		margin-right: -16.666666667%;
		/* Add column gutter */
		margin-left: rem(map-get($islay_flexgrid_settings, gutter) * 2);
	}
}

/*---- Element ordering ----*/
.l-order--max-l {
	@include islay_breakpoint('l', 'max') {
		display: flex;
		flex-direction: column;

		.l-order--1 { order: 1; }
		.l-order--2 { order: 2; }
		.l-order--3 { order: 3; }
	}
}

.l-order--max-xl {
	@include islay_breakpoint('xl', 'max') {
		display: flex;
		flex-direction: column;

		/* Ordering */
		.l-order--1 { order: 1; }
		.l-order--2 { order: 2; }
		.l-order--3 { order: 3; }

		/* Spacing */
		* {
			margin: 0;
			+ * {
				margin-top: rem(20);
			}
		}
	}
}

/*---- Page pushers ----*/
@include islay_breakpoint('xl') {
	.l-vspace--xl--240 {
		float: left;
		width: 1px;
		height: rem(240);

		+ * {
			clear: left;
		}
	}
}

/*---- Collapse margins ----*/
.l-collapse { margin: 0; }
.l-collapse--top { margin-top: 0; }
.l-collapse--bottom { margin-bottom: 0; }
.l-collapse--left { margin-left: 0; }
.l-collapse--right { margin-right: 0; }

@include islay_breakpoint('xl') {
	.l-collapse--xl--top { margin-top: 0; }
}

/*---- Spacing classes ----*/
.l-spacing {
	> * + * {
		margin-top: rem(40);
	}
}

/*---- Extend hiding classes ----*/
[dir="rtl"] .visually-hidden {
	left: initial;
	right: -10000px;
}