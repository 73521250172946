/*
	
	Page errors

*/

.b-page-error {
	min-height: calc(100vh - 39px);
	padding: rem(40) rem(20);
	color: $white;
	text-align: center;
	background: $black;

	/* Vertical center */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}