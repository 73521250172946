/*---- Button setup ----*/
@mixin islay_button_setup($height: map-get($islay_button_settings, height), $padding: map-get($islay_button_settings, padding)) {
  display: inline-block;
  height: $height;
  padding: 0 $padding;
  line-height: $height;
  text-align: center;
  text-decoration: none;

}

/*---- Full button ----*/
@mixin islay_button(
  $height: map-get($islay_button_settings, height),
  $padding: map-get($islay_button_settings, padding),
  $bg: map-get($islay_button_settings, background),
  $color: map-get($islay_button_settings, color),
  $weight: map-get($islay_button_settings, font-weight),
  $hover_bg: map-get($islay_button_hover_settings, background),
  $border: map-get($islay_button_settings, border),
  $hover_color: map-get($islay_button_hover_settings, color),
  $hover_border: map-get($islay_button_hover_settings, border),
  $radius: map-get($islay_button_settings, border-radius),
  $transition: map-get($islay_button_settings, transition)
) {

  @include islay_button_setup($height, $padding);
  
  font-weight: $weight;
  color: $color;
  background: $bg;
  border: $border;
  border-radius: $radius;
  transition: $transition;

  &:visited {
    color: $color;
  }

  &:hover {
    color: $color;
    text-decoration: none;
    background: $hover_bg;
    border: $hover_border;
  }

  &:active {
    @if $islay_button_click_pixel {
      position: relative;
      top: 1px;
    }
  }
}