@import "settings";
@import "functions";

form {
	width: 100%;
}

fieldset {
	padding: 0;
	margin: 0;
	border: none;
}

input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="date"], input[type="url"], input[type="password"], input[type="search"], textarea {
	height: map-get($islay_input_settings, height);
	padding: 0 map-get($islay_input_settings, padding);

	background: map-get($islay_input_settings, background);
	border: map-get($islay_input_settings, border);

	/* Remove rounded corners in ios */
	-webkit-appearance: none;
    border-radius: map-get($islay_input_settings, border-radius);
}

textarea {
	height: auto;
	padding: map-get($islay_input_settings, padding);
	resize: vertical;
}

button, input[type="submit"], input[type="reset"] {
	height: map-get($islay_submit_settings, height);
	padding: 0 map-get($islay_submit_settings, padding);
	color: map-get($islay_submit_settings, color);
	font-family: map-get($islay_submit_settings, font-family);
	font-weight: map-get($islay_submit_settings, font-weight);
	border: map-get($islay_submit_settings, border);
	background: map-get($islay_submit_settings, background);
	border-radius: map-get($islay_submit_settings, radius);
	transition: background map-get($islay_submit_settings, transition);

	&:hover {
		color: map-get($islay_submit_hover_settings, color);
		border: map-get($islay_submit_hover_settings, border);
		background: map-get($islay_submit_hover_settings, background);
	}

	&:active {
		@if $islay_submit_click_pixel {
	      position: relative;
	      top: 1px;
	    }
	}
}

.input-placeholder {
	font-weight: map-get($islay_placeholder_settings, font-weight);
	font-family: map-get($islay_placeholder_settings, font-family);
	color: map-get($islay_placeholder_settings, color);
	text-transform: map-get($islay_placeholder_settings, text-transform);
}
input-placeholder {
	font-weight: map-get($islay_placeholder_settings, font-weight);
	font-family: map-get($islay_placeholder_settings, font-family);
	color: map-get($islay_placeholder_settings, color);
	text-transform: map-get($islay_placeholder_settings, text-transform);
	opacity: 1;
}
::-webkit-input-placeholder {
	font-weight: map-get($islay_placeholder_settings, font-weight);
	font-family: map-get($islay_placeholder_settings, font-family);
	color: map-get($islay_placeholder_settings, color);
	text-transform: map-get($islay_placeholder_settings, text-transform);
	opacity: 1;
}
:-moz-placeholder {
	font-weight: map-get($islay_placeholder_settings, font-weight);
	font-family: map-get($islay_placeholder_settings, font-family);
	color: map-get($islay_placeholder_settings, color);
	text-transform: map-get($islay_placeholder_settings, text-transform);
	opacity: 1;
}
::-moz-placeholder {
	font-weight: map-get($islay_placeholder_settings, font-weight);
	font-family: map-get($islay_placeholder_settings, font-family);
	color: map-get($islay_placeholder_settings, color);
	text-transform: map-get($islay_placeholder_settings, text-transform);
	opacity: 1;
}
:-ms-input-placeholder {
	font-weight: map-get($islay_placeholder_settings, font-weight);
	font-family: map-get($islay_placeholder_settings, font-family);
	color: map-get($islay_placeholder_settings, color);
	text-transform: map-get($islay_placeholder_settings, text-transform);
	opacity: 1;
}