@import "settings";
@import "mixins";

.button {
	@include islay_button_setup();
}

.button--primary {
	@include islay_button();
}

.button--block {
	display: block;
}