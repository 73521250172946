/*
	
	Global

*/

/*---- Variables ----*/
$small_container_settings: map-get($islay_container_settings, "s");
$small_grid_padding: map-get($small_container_settings, padding);
$small_grid_width: map-get($small_container_settings, max-width);

$gutter: map-get($islay_flexgrid_settings, gutter);

$breakpoint_m: rem(map-get($islay_breakpoints, 'm'));
$breakpoint_l: rem(map-get($islay_breakpoints, 'l'));
$breakpoint_xl: rem(map-get($islay_breakpoints, 'xl'));

/*---- General ----*/
body {
	/* Allow space for fixed nav */
	padding-top: 39px;
	margin: 0;
}

/*---- Navigation ----*/
.b-nav-default {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	/* Sits above video header poster div */
	z-index: 12;

	/* Language select positioning */
	.c-lang-select {
		position: absolute;
		top: 0;

		[dir="ltr"] & {
			right: 0;
		}
		[dir="rtl"] & {
			left: 0;
		}

		@include islay_breakpoint('l') {
			position: relative;
			display: inline-block;
		}
	}

	@include islay_breakpoint('l') {
		position: fixed;
		text-align: center;
	}
}

/*---- Home header ----*/
.b-header-home {
	position: relative;

	/* Fixed logo */
	.c-site-logos {
		position: absolute;
		top: 0;
		right: 0;

		/* Sits above video header poster div but below nav bar */
		z-index: 11;
	}

	/* Fixed social icons */
	.c-share-links:first-child {
		display: none;

		@include islay_breakpoint('l') {
			display: block;
			position: absolute;
			top: rem(-40);
			right: 0;
			z-index: 12;
		}
	}
}

/*---- Homepage info panel ----*/
.b-page-intro {
	padding-top: rem(30);

	@include islay_breakpoint('l') {
		padding: rem(30) 0;
	}

	.container {
		position: relative;

		/* Element spacing */
		* {
			margin: 0;
			+ * {
				margin-top: rem(30);
			}
		}
	}

	/* Position social icons */
	.c-share-links {
		/* Collapse gutters for small */
		width: calc(100% + (#{$small_grid_padding} * 2));
		margin-left: -$small_grid_padding;
		margin-right: -$small_grid_padding;

		@media(min-width: #{$small_grid_width}) {
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		}

		@include islay_breakpoint('l') {
			width: auto;
			position: absolute;
			z-index: 1;
			/* Account for section padding */
			bottom: rem(-30);
			transform: translateY(100%);

			/* IE9 fix */
			.no-csstransforms3d & {
				bottom: -86px;
			}

			[dir="ltr"] & {
				left: $gutter;
			}
			[dir="rtl"] & {
				right: $gutter;
			}
		}	
	}

	/* Position action button */
	.c-action-button {
		@include islay_breakpoint('l') {
			position: absolute;
			z-index: 1;
			/* Account for section padding */
			bottom: rem(-30);
			transform: translateY(100%);

			/* IE9 fix */
			.no-csstransforms3d & {
				bottom: -88px;
			}

			[dir="ltr"] & {
				right: $gutter;
			}
			[dir="rtl"] & {
				left: $gutter;
			}
		}

		@include islay_breakpoint('xl') {
			transform: translateY(60%);
		}
	}
}

/*---- Page content ----*/
.b-page-section__content {
	/* Layout for medium */
	@media (min-width: #{$breakpoint_m}) and (max-width: #{$breakpoint_xl}) {
		> div {
			@include islay_flexgrid_row();

			> div, > aside { @include islay_flexgrid_col_setup(); }
			> div { @include islay_flexgrid_alt_col_span(12); }
			> aside { @include islay_flexgrid_alt_col_span(6); }
		}		
	}

	/* spacing */
	[class*="l-callout-"] {
		@include islay_breakpoint('xl', 'max') {
			/* spacing for callouts in page content */
			margin-top: rem(20);
		}

		@include islay_breakpoint('m', 'max') {
			/* extra spacing for first callout below section content */
			&:first-child {
				margin-top: rem(40);
			}
		}
	}
}

/*---- Action ----*/
.b-action {
	+ * {
		margin-top: rem(30);
		@include islay_breakpoint('l') {
			margin-top: rem(60);
		}
	}
}

.b-action-wrap {}

.b-action-wrap__col-1 {
	padding-top: rem(40);
	padding-bottom: rem(40);

	@include islay_breakpoint('l') {
		margin-top: 54%;		
	}
}

.b-action-wrap__col-2 {
	/* Default spacing */
	> * + * {
		margin-top: rem(40);

		@include islay_breakpoint('l') {
			margin-top: rem(60);
		}
	}

	@include islay_breakpoint('l') {
		/* Shift vertically and layer over image - doesn't work for IE Edge */
		// margin-top: -44%;
		margin-top: rem(80);		
	}

	@media (min-width: #{$breakpoint_l}) and (max-width: #{$breakpoint_xl}) {
		/* Collapse gutter for l to xl */
		//padding-right: 0;
	}
}

.b-action-wrap__image {
	@include islay_breakpoint('l') {
		position: absolute;
		width: 63%;
		max-width: rem(900);

		/* Overide default media wrap */
		padding-bottom: 45%;

	}
}

.b-action__stage {
	/* Adjust spacing for share iframes component */
	.c-share-iframes {
		margin-top: rem(16);
	}
}

.c-action-progress {

}

/*---- Footer ----*/
.b-footer-home {
	> .container {
		> * + * {
			margin-top: rem(45);
		}
	}

	.c-site-logos {
		margin: rem(45) auto 0;
	}
}

.b-footer-home__logo {
	display: block;
	margin: auto;

	+ * {
		margin-top: rem(10);
	}
}
