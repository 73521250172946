@import "settings";
@import "functions";
@import "mixins";

/*---- Columns ----*/
// Build default grid classes
@include islay_flexgrid_generate_col_classes();

/* Padding gutters */
%col, .col {
	@include islay_flexgrid_col_setup;
	padding-left: rem($islay_flexgrid_gutter);
	padding-right: rem($islay_flexgrid_gutter);
}

/* Margin gutters */
%alt-col, .alt-col {
	@include islay_flexgrid_col_setup;
	margin-left: rem($islay_flexgrid_gutter);
	margin-right: rem($islay_flexgrid_gutter);
}

/* test column */
.col--test {
	height: 100px;
	background: #999;

	&:nth-child(even) {
		background: darken(#999, 10);
	}
}

/* Remove column gutters */
.collapse { 
	padding-right: 0;
	padding-left: 0; 
}

.alt-collapse { 
	margin-right: 0;
	margin-left: 0; 
}


/*---- Rows ----*/

/* Create a row */
.row {
	@include islay_flexgrid_row();
}

/* Generate rows, gutters & cols for islay breakpoints */
@include islay_flexgrid_breakpoint_rows();

/* Add external grid gutters */
.gutter {
	@include islay_flexgrid_gutter;
}

.alt-gutter {
	@include islay_flexgrid_alt_gutter;
}

/* Align column items */
.row--top { align-items: flex-start; }
.row--center { align-items: center; }
.row--bottom { align-items: flex-end; }
.row--stretch { align-items: stretch; }