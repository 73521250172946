/*
	
	Buttons

*/

/*---- Main CTA button ----*/
%c-action-button-setup {
	background: $white;
	border: 4px solid $white;
	background-clip: content-box;
}

.c-action-button {
	@include islay_button_setup(inherit, 5px);
	@extend .t-scale-1;
	@extend %c-action-button-setup;

	padding: rem(5px);
	color: $black;
	font-family: $title_font;
	text-transform: uppercase;

	span {
		display: block;

		[dir="ltr"] & {
			padding: rem(7) 0 rem(7) rem(20);
		}

		[dir="rtl"] & {
			padding: rem(7) rem(20) rem(7) 0;
		}
	}

	/* Position icon */
	i {
		position: relative;
		top: 0;
		left: -10px;
		font-size: 80%;

			[dir="rtl"] & {
				left: 0;
				display: inline-block;
				transform: rotate(180deg);
			}
	}

	/* Overwrite standard button hover states */
	&:hover {
		@extend %c-action-button-setup;
		color: $primary_color;
	}

	&:disabled {
		color: lighten($black, 60);
	}

}

.c-action-button--small {
	@extend .t-scale-3;	

	span {
		padding: rem(2) 0 rem(2) rem(20);
	}
}

/*---- Share buttons ----*/
[class*="c-share-button"] {
	display: block;
	height: 43px;
	line-height: 43px;
	padding: 0 rem(20);
	color: $white;
	text-decoration: none;
	text-align: center;
	font-family: $title_font;
	text-transform: uppercase;

	&:hover {
		color: $white;
	}

	/* Adjust icon positioning */
	i {
		position: relative;
		top: 2px;
	}
}

/* Sizing */
.c-share-button--sq {
	padding: 0;
	width: 43px;
}

.c-share-button--md {
	@extend .t-scale-4;
	height: 56px;
	line-height: 56px;
}

.c-share-button--lg {
	@extend .t-scale-5;
	height: 60px;
	line-height: 60px;
}

/* Facebook */
.c-share-button--fb {
	background: $fb_blue;
	&:hover { background: saturate($fb_blue, 40); }
}

/* Twitter */
.c-share-button--tw {
	background: $tw_blue;
	&:hover { background: lighten($tw_blue, 10); }
}

/* Email */
.c-share-button--email {
	background: $black;
	&:hover { background: lighten($black, 10); }
}