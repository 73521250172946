/*

	Example typography setup for project
	classname prefix: t-

*/

/*---- Import fonts from Google ----*/
@import url(https://fonts.googleapis.com/css?family=Oxygen:400,700);

/*---- Import local fonts ----*/
// @include islay_generate_font_face('TestFontName', 'testfontnameclass', 'path/to/file');

/*---- Generate class names for fonts ----*/
@include islay_generate_font_face_class_name('Oxygen', 'oxygen');
@include islay_generate_font_face_class_name('din-condensed-web', 'din');

/*---- Create a default font-size ----*/
.t-scale-default {
	@each $breakpoint, $map in $islay_type_scales {
		@include islay_breakpoint($breakpoint) {
			font-size: rem(map-get($map, base));
			line-height: map-get($map, line-height);
		}
	}
}

/*---- General ----*/
body {
	color: $black;
}

strong {
	font-weight: $bold;
}

/*---- Create heading styles ----*/
h1, h2, h3, h4, h5, h6, %title-setup {
	margin: 0;
	font-family: $title_font;
	font-weight: 400;
	text-transform: uppercase;
}

/* Spacing */
* + {	
	h1, h2, h3, h4, h5, h6 {
		margin-top: rem(34);
	}
}

h1, .t-scale-1, .t-title-1 {
	@include islay_generate_heading_breakpoint_sizes(5);
	line-height: 0.9;
}

h2, .t-scale-2, .t-title-2 {
	@include islay_generate_heading_breakpoint_sizes(4);
	line-height: 1;
}

h3, .t-scale-3, .t-title-3 {
	@include islay_generate_heading_breakpoint_sizes(3);
	line-height: 1;
}

h4, .t-scale-4, .t-title-4 {
	@include islay_generate_heading_breakpoint_sizes(2);
	line-height: 1;
}

h5, .t-scale-5, .t-title-5 {
	@include islay_generate_heading_breakpoint_sizes(1);
	line-height: 1;
}

.t-title-1, .t-title-2, .t-title-3, .t-title-4, .t-title-5 {
	@extend %title-setup;
}


/*---- Titles ----*/
.t-section-title {
	@extend .t-title-1;
	color: $white;

	/* Large text */
	span {
		display: block;
		margin: -0.8% 0 0 -0.8%;
		font-size: 300%;
	}
}

/*---- Quotes ----*/
[class*="t-quote-"] {
	/* Text spacing */
	p {
		margin: 0;
		+ p {
			margin-top: rem(20);
		}
	}
}

.t-quote-primary {
	@extend .t-scale-1;
	font-family: $title_font;
	text-transform: uppercase;
	line-height: 0.9;
	text-align: center;
}

.t-quote-primary__cite {
	font-size: 60%;
}

.t-quote-secondary {
	@extend .t-scale-3;
	font-family: $title_font;
	text-transform: uppercase;
	line-height: 0.9;
	text-align: center;
	color: $primary_color;
}

/*---- Links ----*/
a {
	color: $primary_color;
	
	&:hover {
		color: darken($primary_color, 10);
	}
}

/*---- Text spacing ----*/
.t-page-text {
	* {
		margin: 0;
		+ * {
			margin-top: rem(34);
		}
	}
}

/*---- Alignment ----*/
.t-left { text-align: left; }
.t-center { text-align: center; }
.t-right { text-align: right; }