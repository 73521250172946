/*
	
	Logos & Branding

*/

.c-site-logos {
	max-width: rem(68);

	a {
		display: block;
	}

	/* Spacing for multiple logos */
	> * + * {
		margin-top: 2px;
	}

	@include islay_breakpoint('m') {
		max-width: rem(86);
	}
}