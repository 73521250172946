@import "settings";
@import "functions";
@import "mixins";

html {
	@each $breakpoint, $map in $islay_type_scales {
		@include islay_breakpoint($breakpoint) {
			
			/* 
				Set font-size as a percentage
				16 references general default browser font-size of 16px
			*/
			font-size: (map-get($map, base) / 16) * 100%;
		}
	}
}

body {
	font-family: map-get($islay_type_settings, body-font-family);
	
	@each $breakpoint, $map in $islay_type_scales {
		@include islay_breakpoint($breakpoint) {
			
			/* Set line-height */
			line-height: map-get($map, line-height);
		}
	}
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid;
    margin: 10px 0;
    padding: 0;
}


p {
	text-rendering: optimizeLegibility;
}

ul, ol {
	margin: 0;
	padding: 0;
}

blockquote {
	margin: 0;
}