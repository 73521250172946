/*
	
	Action

*/


/*---- Action section ----*/
.b-action-wrap {
	overflow: hidden;
	padding-bottom: rem(280);
	position: relative;
	background: $black url(../img/backgrounds/partition-alt-01-small.png) center bottom no-repeat;
	background-size: contain;

	@include islay_breakpoint('m') {
		padding-bottom: rem(366);
		background-image: url(../img/backgrounds/partition-alt-01.png);
	}
}

.b-action-wrap__image {

	/* Overide default media wrap behaviour */
	img {
		// width: auto;
		// max-width: 100%;
	}
}

/*---- Action box ----*/
.b-action {
	position: relative;
	padding: rem(20);
	background: $blue;

	@include islay_breakpoint('l', 'max') {
		/* Full width background for small */
		&:before, &:after {
			@include islay_pseudo_block();
			width: 1000px;
			top: 0;
			bottom: 0;
			background: $blue;
		}

		&:before {
			left: 0;
			transform: translateX(-100%);
		}

		&:after {
			right: 0;
			transform: translateX(100%);
		}
	}
}

.b-action__stage {
	padding: 5px 0;
	/* Hide for multiform */
	display: none;
	
	/* Spacing */
	> * {
		margin: 0;
		+ * {
			margin-top: rem(30);
		}
	}

	&:first-child {
		/* Show first stage for multiform */
		display: block;
	}
}

.b-action__form {
	position: relative;
	@include islay_breakpoint('l') {
		padding-bottom: rem(30);
	}

	/* Position button */
	button {
		margin-top: rem(10);

		@include islay_breakpoint('l') {
			position: absolute;
			right: 0;

			&:active {
				position: absolute;
				top: auto;
			}
		}
	}
}