/*---- Create pseudo blocks ----*/
@mixin islay_pseudo_block($content: '', $display: block, $position: absolute, $zindex: 1) {
  content: $content;
  display: $display;
  position: $position;
  z-index: $zindex;
}

/*---- Create arrows ----*/
@mixin islay_arrow($size, $color, $direction) {
  width: 0; 
  height: 0;

  @if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }

  @if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  }

  @if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }

  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }
}