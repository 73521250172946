/*
	
	Nav

*/

/*---- Placeholders ----*/
%nav-link {
	height: 40px;
	line-height: 42px;
	padding: 0 rem(40);

	font-family: $title_font;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		color: $white;
	}

	@include islay_breakpoint('l') {
		padding: 0 rem(10);
	}
}

/*---- Main navigation ----*/
.b-nav-default {
	background: $black;

	// Mobile navigation toggle
	@include islay_breakpoint('l', 'max') {
		&.is-visible {
			.b-nav-default__link {
				display: block;

				/* Change nav icon on toggle */
				&:first-child:before {
					content: "\e907";
				}
			}
		}
	}
}

.b-nav-default__link {
	/* Not displayed for mobile dropdown */
	display: none;
	@extend %nav-link;
	border-top: 1px solid #2f2c2d;

	&:first-child {
		position: relative;
		&:before {
			/* Setup nav icon */
			@extend %icon-setup;
			content: "\e908";
			position: absolute;
			transform: translateX(-140%) translateY(92%);
			[dir="rtl"] & { transform: translateX(140%) translateY(92%); }

			@include islay_breakpoint('m') {
				transform: translateX(-140%) translateY(60%);
				[dir="rtl"] & { transform: translateX(140%) translateY(60%); }
			}

			/* Hide for larger screens */
			@include islay_breakpoint('l') {
				display: none;
			}
		}

		/* Show first link for mobile dropdown */
		display: block;
		color: $white;
	}

	@include islay_breakpoint('l') {
		position: relative;
		display: inline-block;
		border: none;
		
		&:before {
			@include islay_pseudo_block();
			top: 10px;
			z-index: 10;
			width: 2px;
			height: 20px;
			background: #2f2c2d;

			[dir="ltr"] & {
				left: -2px;
			}

			[dir="rtl"] & {
				right: -2px;
			}
		}

		&:first-child {
			display: inline-block;
		}
	}

	&.b-nav-default__link--updates {
		background-color: white;
		color: #2f2c2d;
		&:before,
		& + .b-nav-default__link:before {
			display: none;
		}
	}
}

/*---- Language switcher ----*/
.c-lang-select {
	position: relative;
}

.c-lang-select__current {
	@extend %nav-link;
	margin: 0;
	color: $primary_color;
	cursor: pointer;

	/* Nav border */
	&:before {
		@include islay_breakpoint('l') {
			@include islay_pseudo_block();
			top: 10px;
			z-index: 10;
			width: 2px;
			height: 20px;
			background: #2f2c2d;

			[dir="ltr"] & {
				left: -2px;
			}

			[dir="rtl"] & {
				right: -2px;
			}
		}
	}

	/* Dropdown arrow */
	&:after {
		@include islay_pseudo_block();
		@include islay_arrow(4px, $primary_color, down);
		top: 50%;
		margin-top: -2px;

		[dir="ltr"] & { right: 4px; }
		[dir="rtl"] & { left: 4px; }
		@include islay_breakpoint('l') {
			[dir="ltr"] & { right: -4px; }
			[dir="rtl"] & { left: -4px; }
		}
	}

	&:hover:after {
		border-top-color: $white;
	}
}

.c-lang-select__list {
	position: absolute;
	background: $black;
	display: none;

	.is-visible & {
		display: block;
	}
}

.c-lang-select__item {
	position: relative;
	list-style: none;
}

.c-lang-select__link {
	@extend %nav-link;
}

/* Hide selected language */

[data-locale="ar"] [data-localeselect="ar"] { display: none; }
[data-locale="bg"] [data-localeselect="bg"] { display: none; }
[data-locale="de"] [data-localeselect="de"] { display: none; } 
[data-locale="en"] [data-localeselect="en"] { display: none; }
[data-locale="es"] [data-localeselect="es"] { display: none; } 
[data-locale="fa"] [data-localeselect="fa"] { display: none; }
[data-locale="fi"] [data-localeselect="fi"] { display: none; }
[data-locale="fr"] [data-localeselect="fr"] { display: none; } 
[data-locale="hy"] [data-localeselect="hy"] { display: none; }
[data-locale="it"] [data-localeselect="it"] { display: none; }
[data-locale="ku"] [data-localeselect="ku"] { display: none; }
[data-locale="nl"] [data-localeselect="nl"] { display: none; }
[data-locale="no"] [data-localeselect="no"] { display: none; }
[data-locale="pl"] [data-localeselect="pl"] { display: none; }
[data-locale="pt"] [data-localeselect="pt"] { display: none; }
[data-locale="ro"] [data-localeselect="ro"] { display: none; }
[data-locale="ru"] [data-localeselect="ru"] { display: none; } 
[data-locale="sv"] [data-localeselect="sv"] { display: none; }
[data-locale="tr"] [data-localeselect="tr"] { display: none; }
[data-locale="zh"] [data-localeselect="zh"] { display: none; }
