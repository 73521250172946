/*
	
	Signups

*/

.c-action-signups {
	font-family: $title_font;
}

.c-action-signups__item {
	list-style: none;
	display: table;
	width: 100%;

	+ * {
		margin-top: rem(5);
	}

	> span {
		display: table-cell;
		vertical-align: middle;

		+ span {
            [dir="ltr"] & {
                padding-left: rem(10);
            }
            [dir="rtl"] & {
                padding-right: rem(10);
            }
		}
		&:last-child {
            [dir="ltr"] & {
                text-align: right;
            }
            [dir="rtl"] & {
                text-align: left;
            }
		}
	}
}

/*---- Flags ----*/
.c-flag {
	height: 33px;
	width: 48px;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: 48px;

	&.YE { background-image: url("../img/flags/_0000_Yemen.png"); }
    &.VN { background-image: url("../img/flags/_0001_Vietnam.png"); }
    &.VE { background-image: url("../img/flags/_0002_Venezuela.png"); }
    &.US { background-image: url("../img/flags/_0003_United-States.png"); }
    &.UA { background-image: url("../img/flags/_0004_Ukraine.png"); }
    &.TZ { background-image: url("../img/flags/_0005_Tanzania.png"); }
    &.TR { background-image: url("../img/flags/_0006_Turkey.png"); }
    &.TL { background-image: url("../img/flags/_0007_East-Timor.png"); }
    &.TH { background-image: url("../img/flags/_0008_Thailand.png"); }
    &.TJ { background-image: url("../img/flags/_0009_Tajikistan.png"); }
    &.SV { background-image: url("../img/flags/_0010_El-Salvador.png"); }
    &.SY { background-image: url("../img/flags/_0011_Syria.png"); }
    &.SO { background-image: url("../img/flags/_0012_Somalia.png"); }
    &.SN { background-image: url("../img/flags/_0013_Senegal.png"); }
    &.SL { background-image: url("../img/flags/_0014_Sierra-Leone.png"); }
    &.SG { background-image: url("../img/flags/_0015_Singapore.png"); }
    &.SE { background-image: url("../img/flags/_0016_Sweden.png"); }
    &.ZA { background-image: url("../img/flags/_0017_South-Africa.png"); }
    &.SK { background-image: url("../img/flags/_0018_Slovakia.png"); }
    &.SA { background-image: url("../img/flags/_0019_Saudi-Arabia.png"); }
    &.RW { background-image: url("../img/flags/_0020_Rwanda.png"); }
    &.RU { background-image: url("../img/flags/_0021_Russia.png"); }
    &.RO { background-image: url("../img/flags/_0022_Romania.png"); }
    &.QA { background-image: url("../img/flags/_0023_Quatar.png"); }
    &.PH { background-image: url("../img/flags/_0024_Phillipines.png"); }
    &.PY { background-image: url("../img/flags/_0025_Paraguay.png"); }
    &.PT { background-image: url("../img/flags/_0026_Portugal.png"); }
    &.PL { background-image: url("../img/flags/_0027_Poland.png"); }
    &.PK { background-image: url("../img/flags/_0028_Pakistan.png"); }
    &.PG { background-image: url("../img/flags/_0029_Papau-New-Guinea.png"); }
    &.PE { background-image: url("../img/flags/_0030_Peru.png"); }
    &.PA { background-image: url("../img/flags/_0031_Panama.png"); }
    &.OM { background-image: url("../img/flags/_0032_Oman.png"); }
    &.NO { background-image: url("../img/flags/_0033_Norway.png"); }
    &.NZ { background-image: url("../img/flags/_0034_New-Zealand.png"); }
    &.NL { background-image: url("../img/flags/_0035_Netherlands.png"); }
    &.NI { background-image: url("../img/flags/_0036_Nicaragua.png"); }
    &.NG { background-image: url("../img/flags/_0037_Nigeria.png"); }
    &.NE { background-image: url("../img/flags/_0038_Niger-Republic-Of.png"); }
    &.NA { background-image: url("../img/flags/_0039_Namibia.png"); }
    &.MX { background-image: url("../img/flags/_0040_Mexico.png"); }
    &.MT { background-image: url("../img/flags/_0041_Malta.png"); }
    &.MM { background-image: url("../img/flags/_0042_Myanmar.png"); }
    &.ML { background-image: url("../img/flags/_0043_Mali.png"); }
    &.MY { background-image: url("../img/flags/_0044_Malaysia.png"); }
    &.MK { background-image: url("../img/flags/_0045_Macedonia.png"); }
    &.MG { background-image: url("../img/flags/_0046_Madagascar.png"); }
    &.LV { background-image: url("../img/flags/_0047_Latvia.png"); }
    &.LU { background-image: url("../img/flags/_0048_Luxembourg.png"); }
    &.LS { background-image: url("../img/flags/_0049_Lesotho.png"); }
    &.LC { background-image: url("../img/flags/_0050_Saint-Lucia.png"); }
    &.LB { background-image: url("../img/flags/_0051_Lebanon.png"); }
    &.LA { background-image: url("../img/flags/_0052_Laos.png"); }
    &.KZ { background-image: url("../img/flags/_0053_Kazakhstan.png"); }
    &.KW { background-image: url("../img/flags/_0054_Kuwait.png"); }
    &.KR { background-image: url("../img/flags/_0055_South-Korea.png"); }
    &.KP { background-image: url("../img/flags/_0056_North-Korea.png"); }
    &.KN { background-image: url("../img/flags/_0057_St-Kitts-Nevis.png"); }
    &.KG { background-image: url("../img/flags/_0058_Kyrgyzstan.png"); }
    &.JP { background-image: url("../img/flags/_0059_Japan.png"); }
    &.JO { background-image: url("../img/flags/_0060_Jordan.png"); }
    &.JM { background-image: url("../img/flags/_0061_Jamaica.png"); }
    &.IT { background-image: url("../img/flags/_0062_Italy.png"); }
    &.IR { background-image: url("../img/flags/_0063_Iran.png"); }
    &.IQ { background-image: url("../img/flags/_0064_Iraq.png"); }
    &.IN { background-image: url("../img/flags/_0065_India.png"); }
    &.IL { background-image: url("../img/flags/_0066_Israel.png"); }
    &.IE { background-image: url("../img/flags/_0067_Ireland.png"); }
    &.ID { background-image: url("../img/flags/_0068_Indonesia.png"); }
    &.HU { background-image: url("../img/flags/_0069_Hungary.png"); }
    &.HK { background-image: url("../img/flags/_0070_Hong-Kong.png"); }
    &.HT { background-image: url("../img/flags/_0071_Haiti.png"); }
    &.HN { background-image: url("../img/flags/_0072_Honduras.png"); }
    &.GT { background-image: url("../img/flags/_0073_Guatemala.png"); }
    &.GR { background-image: url("../img/flags/_0074_Greece.png"); }
    &.GM { background-image: url("../img/flags/_0075_Gambia.png"); }
    &.GB { background-image: url("../img/flags/_0076_Great-Britain.png"); }
    &.GA { background-image: url("../img/flags/_0077_Gabon.png"); }
    &.FR { background-image: url("../img/flags/_0078_France.png"); }
    &.FI { background-image: url("../img/flags/_0079_Finland.png"); }
    &.ES { background-image: url("../img/flags/_0080_Spain.png"); }
    &.EG { background-image: url("../img/flags/_0081_Egypt.png"); }
    &.EE { background-image: url("../img/flags/_0082_Estonia.png"); }
    &.DZ { background-image: url("../img/flags/_0083_Algeria.png"); }
    &.DK { background-image: url("../img/flags/_0084_Denmark.png"); }
    &.DJ { background-image: url("../img/flags/_0085_Djibouti.png"); }
    &.DE { background-image: url("../img/flags/_0086_Germany.png"); }
    &.CZ { background-image: url("../img/flags/_0087_Czech-Republic.png"); }
    &.CO { background-image: url("../img/flags/_0088_Colombia.png"); }
    &.CN { background-image: url("../img/flags/_0089_China.png"); }
    &.CM { background-image: url("../img/flags/_0090_Cameroon.png"); }
    &.CL { background-image: url("../img/flags/_0091_Chile.png"); }
    &.TD { background-image: url("../img/flags/_0092_Chad.png"); }
    &.CH { background-image: url("../img/flags/_0093_Switzerland.png"); }
    &.CG { background-image: url("../img/flags/_0094_Republic-of-Congo.png"); }
    &.CD { background-image: url("../img/flags/_0095_Democratic-Rebuplic-of-Congo.png"); }
    &.CA { background-image: url("../img/flags/_0096_Canada.png"); }
    &.BR { background-image: url("../img/flags/_0097_Brazil.png"); }
    &.BO { background-image: url("../img/flags/_0098_Bolivia.png"); }
    &.BG { background-image: url("../img/flags/_0099_Bulgaria.png"); }
    &.BF { background-image: url("../img/flags/_0100_Burkina-Faso.png"); }
    &.BE { background-image: url("../img/flags/_0101_Belgium.png"); }
    &.AU { background-image: url("../img/flags/_0102_Australia.png"); }
    &.AT { background-image: url("../img/flags/_0103_Austria.png"); }
    &.AR { background-image: url("../img/flags/_0104_Argentina.png"); }
    &.AM { background-image: url("../img/flags/_0105_Armenia.png"); }
    &.AG { background-image: url("../img/flags/_0106_Antigua-And-Barbuda.png"); }
    &.AE { background-image: url("../img/flags/_0107_United-Arab-Emirates.png"); }
    &.AD { background-image: url("../img/flags/_0108_Andorra.png"); }
}