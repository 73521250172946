/*---- Convert PX to EM (eg. em(14px)) ----*/
@function em($pixels, $context: $islay_base_font_size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

/*---- Convert PX to REM ----*/
@function rem($pixels, $context: $islay_base_font_size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

/*---- Convert PX to % (eg. pc(14px)) ----*/
@function pc($target, $context: $islay_base_font_size) {
  @if $target == 0 { @return 0 }
  @return ($target / ($context * 1px)) * 100%;
}